<template>
  <div>
    <b-modal
      v-model="control"
      title-class="h3 text-white"
      header-class="headerColor"
      header-bg-variant="primary"
      centered
      size="lg"
      :title="`Digital Migration Request`"
      modal-class="modal-primary"
      @hidden="close"
    >
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >ACCOUNT</b-input-group-text
                >
              </template>
              <b-form-input
                style="'border-color:rgba(255, 255, 255, 0.4)'; "
                :style="
                  isDarkSkin
                    ? 'background: #17171A;opacity: 0.7;color:white'
                    : 'background: #efefef'
                "
                v-model="currentClient.account"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >CLIENT</b-input-group-text
                >
              </template>
              <b-form-input
                style="'border-color:rgba(255, 255, 255, 0.4)';"
                :style="
                  isDarkSkin
                    ? 'background: #17171A;opacity: 0.7;color:white'
                    : 'background: #efefef'
                "
                v-model="currentClient.client_name"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <b-input-group size="sm" class="mt-1 mb-0" v-if="!isThereNewFormat">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >PREVIOUS TIME FORMAT</b-input-group-text
                >
              </template>
              <b-form-input
                style="'border-color:rgba(255, 255, 255, 0.4)'; "
                :style="
                  isDarkSkin
                    ? 'background: #17171A;opacity: 0.7;color:white'
                    : 'background: #efefef'
                "
                v-model="oldTimeFormat"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <ValidationProvider
              v-if="!isThereNewFormat"
              v-slot="{ errors }"
              name="contactHour"
              rules="required"
            >
              <b-form-group
                id="contactHour"
                label-class="font-weight-bolder"
                label-for="input-horizontal"
              >
                <template #label>
                  <div class="d-flex justify-content-between kendo-width">
                    <div>Hour:</div>
                    <div
                      :id="'hours-target'"
                      class="text-primary cursor-pointer"
                    >
                      ?
                    </div>

                    <b-tooltip
                      v-if="hoursToSee"
                      custom-class="ga"
                      :target="'hours-target'"
                      triggers="hover"
                      placement="top"
                    >
                      <div>
                        <h5 class="font-weight-bolder">Office Hours</h5>

                        <span v-for="(item, i) in hoursToSee" :key="i">
                          <span
                            class="font-weight-bolder text-justify"
                            style="max-width: 1px !important"
                            >{{ item.day }}
                          </span>
                          <span
                            class="text-justify"
                            style="max-width: 10px !important"
                          >
                            {{
                              ": From: " +
                              (item.from ? item.from : "00:00") +
                              " To: " +
                              (item.to ? item.to : "23:59")
                            }}</span
                          >
                          <br />
                        </span>
                      </div>
                    </b-tooltip>
                  </div>
                </template>
                <div class="d-flex justify-content-between">
                  <p class="text-danger font-weight-light mb-0 mt-0">
                    Please set the time to call this client according to the
                    information showed above:
                  </p>
                </div>
                <div class="w-25">
                  <kendo-timepicker
                    id="contactHour"
                    v-model="newHourToContact"
                    :format="'HH:mm'"
                    minutes-step="30"
                    class="w-100 rounded bg-transparent k-picker-custom"
                    :class="{
                      'border-danger': errors[0] || !newHourToContactIsValid,
                    }"
                    placeholder="Select hour"
                    @input="showChangedValue()"
                  />
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Observation"
              rules="required"
            >
              <b-form-group
                label="Observation"
                label-for="Observation"
                label-cols-md="2"
              >
                <b-form-textarea
                  id="Observation"
                  :state="errors[0] ? false : null"
                  v-model="observation"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button variant="danger" @click="close">Cancel</b-button>
        <b-button
          :disabled="loading"
          variant="success"
          @click="respondMigrationRequest(1)"
          >Migrate</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import MigrationClientsService from "../../services/clients.services";
import moment from "moment";
export default {
  props: {
    currentClient: {
      type: Object,
      required: true,
    },
    current_user: {
      required: true,
    },
  },
  data() {
    return {
      control: false,
      currentDay: null,
      scheduleForToday: {
        id: null,
        id_update: null,
        day: null,
        from: "00:00",
        to: "23:59",
      },
      dayInModal: null,
      hoursToSee: null,
      TimeFormatsToContact: [],
      newHourToContact: null,
      newHourToContactIsValid: true,
      observation: "",
      loading: false,
      saleClientId: null,
      infoNull: "No related Information",
      isBusy: false,
      validateHours: null,
    };
  },
  async created() {
    this.control = true;
    await this.officeHoursPerDay();
    await this.getClientOldTimeFormat();
  },
  methods: {
    async respondMigrationRequest(actionType) {
      if (!this.newHourToContactIsValid) return;
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      this.$swal
        .fire({
          title: "Are sure want to migrate this client?",
          text: "You won't be able to revert this!",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            this.loading = true;
            this.addPreloader();
            try {
              if (!this.isThereNewFormat) {
                await MigrationClientsService.setNewTimeFormatClients({
                  sale_id: this.saleClientId,
                  answer: this.newHourToContact,
                });
              }
              await MigrationClientsService.DigitalMigrationRequest({
                client_account_id: this.currentClient.id,
                observation: this.observation,
                migration_option: actionType,
                user_id: this.current_user,
              });
              this.$emit("close");
              this.$emit("update");
              this.removePreloader();
            } catch (error) {
              console.log(error);
              this.loading = false;
              this.removePreloader();
            }
          }
        });
    },
    close() {
      this.$emit("close");
      this.control = false;
    },
    // clients-get-old-time-format
    async getClientOldTimeFormat() {
      const params = { client_account_id: this.currentClient.id };
      try {
        let response = await MigrationClientsService.getOldTimeFormatClients(
          params
        );
        if (response.status == 200) {
          this.TimeFormatsToContact = response.data;
          let arrSales = response.data.filter(
            (item) => item.question_id === 71
          );
          if (arrSales.length == 0) {
            arrSales = response.data.filter(
              (item) => item.question_id === 1057
            );
            if (arrSales.length == 0) {
              arrSales = response.data.filter(
                (item) => item.question_id !== null
              );
              if (arrSales.length == 0) {
                arrSales = response.data;
              }
            }
          }
          this.saleClientId = arrSales[0].sale_id;
        }
      } catch (error) {
        console.error("Error trying to get client old time format", error);
      }
    },

    async officeHoursPerDay() {
      try {
        const result = await MigrationClientsService.getOfficeHoursPerDay();
        if (result.status === 200) {
          this.isBusy = true;
          this.hoursToSee = result.data;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (error) {
        console.error(error);
      }
    },

    setScheduleForTodayFromOfficeHours() {
      const now = moment();
      const day = moment(now).format("dddd");
      switch (day) {
        case "Sunday":
          this.dayInModal = "Sunday";
          this.validateHours = 1;
          break;
        case "Monday":
          this.dayInModal = "Monday";
          this.validateHours = 2;
          break;
        case "Tuesday":
          this.dayInModal = "Tuesday";
          this.validateHours = 3;
          break;
        case "Wednesday":
          this.dayInModal = "Wednesday";
          this.validateHours = 4;
          break;
        case "Thursday":
          this.dayInModal = "Thursday";
          this.validateHours = 5;
          break;
        case "Friday":
          this.dayInModal = "Friday";
          this.validateHours = 6;
          break;
        case "Saturday":
          this.dayInModal = "Saturday";
          this.validateHours = 7;
          break;
      }
      this.scheduleForToday = this.hoursToSee.find(
        (day) => day.id === this.validateHours
      );
      const errorMessage = `Valid time is between ${this.scheduleForToday.from} and ${this.scheduleForToday.to}`;
      if (
        this.newHourToContact >= this.scheduleForToday.from &&
        this.newHourToContact <= this.scheduleForToday.to
      ) {
        this.newHourToContactIsValid = true;
      } else {
        this.newHourToContactIsValid = false;
        this.$swal.fire({
          icon: "warning",
          title: "Time not valid!",
          text: errorMessage,
          showConfirmButton: true,
        });
      }
    },
  },
  computed: {
    oldTimeFormat() {
      return (
        this.TimeFormatsToContact.find((element) => element.question_id == 1057)
          ?.answer || this.infoNull
      );
    },
    newTimeFormat() {
      return this.TimeFormatsToContact.find(
        (element) => element.question_id == 71
      )?.answer;
    },
    isThereNewFormat() {
      return !!this.newTimeFormat;
    },
  },
  watch: {
    newHourToContact(newVal) {
      this.setScheduleForTodayFromOfficeHours();
    },
  },
};
</script>

<style scoped>
.headerColor {
  background-color: #0090e7 !important;
}
.kendo-width {
  width: 10%;
}
</style>