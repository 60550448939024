export default [
  {
    key: "selected",
    label: "",
    visible: true,
  },
  {
    key: "lead_name",
    label: "Client",
    visible: true,
    sortable: true,
    orderBy:4
  },
  {
    key: "program",
    label: "Program",
    visible: true,
  },
  {
    key: "date_activation",
    label: "App",
    visible: true,
    sortable: true,
    class: "text-center",
    orderBy:9
  },
  {
    key: "charges",
    label: "Fee & Charges",
    visible: true,
    sortable: true,
    orderBy:10
  },
  {
    key: "monthly_amount",
    label: "MP",
    visible: true,
    sortable: true,
    orderBy:11
  },
  {
    key: "last_payment",
    label: "LP",
    visible: true,
    sortable: true,
    class: "text-center",
    orderBy:25
  },
  {
    key: "type_payment",
    label: "PT",
    visible: true,
    sortable: true,
    class: "text-center",
    orderBy:27
  },
  {
    key: "score",
    label: "Score",
    visible: true,
    sortable: true,
    orderBy:12
  },
  {
    key: "goals",
    label: "Goals",
    visible: true,
  },
  {
    key: "advisor_name",
    label: "Advisor",
    visible: true,
  },
  {
    key: "account_id",
    label: "AC",
    visible: true,
  },
  {
    key: "created_at",
    label: "Creation Date",
    visible: true,
    sortable: true,
    class: "text-center",
    orderBy:17
  },
  {
    key: "services",
    label: "Services",
    visible: true,
  },
  {
    key: "typedoc",
    label: "Type Doc",
    visible: true,
    class: "text-center",
  },
  {
    key: "migration",
    label: "Migration Status",
    visible: true,
    class: "text-center",
  },
  {
    key: "tracking_migration",
    label: "Migration Tracking",
    visible: true,
    class: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
    sortable: true,
  },
];
