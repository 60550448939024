var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title-class":"h3 text-white","header-class":"headerColor","header-bg-variant":"primary","centered":"","size":"lg","title":"Digital Migration Request","modal-class":"modal-primary"},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"success"},on:{"click":function($event){return _vm.respondMigrationRequest(1)}}},[_vm._v("Migrate")])]},proxy:true}]),model:{value:(_vm.control),callback:function ($$v) {_vm.control=$$v},expression:"control"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{staticClass:"mb-1",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"variant":"gradient-primary"}},[_vm._v("ACCOUNT")])]},proxy:true}])},[_c('b-form-input',{staticStyle:{"'border-color":"rgba(255, 255, 255, 0.4)'"},style:(_vm.isDarkSkin
                  ? 'background: #17171A;opacity: 0.7;color:white'
                  : 'background: #efefef'),attrs:{"disabled":"disabled"},model:{value:(_vm.currentClient.account),callback:function ($$v) {_vm.$set(_vm.currentClient, "account", $$v)},expression:"currentClient.account"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{staticClass:"mb-1",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"variant":"gradient-primary"}},[_vm._v("CLIENT")])]},proxy:true}])},[_c('b-form-input',{staticStyle:{"'border-color":"rgba(255, 255, 255, 0.4)'"},style:(_vm.isDarkSkin
                  ? 'background: #17171A;opacity: 0.7;color:white'
                  : 'background: #efefef'),attrs:{"disabled":"disabled"},model:{value:(_vm.currentClient.client_name),callback:function ($$v) {_vm.$set(_vm.currentClient, "client_name", $$v)},expression:"currentClient.client_name"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.isThereNewFormat)?_c('b-input-group',{staticClass:"mt-1 mb-0",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"variant":"gradient-primary"}},[_vm._v("PREVIOUS TIME FORMAT")])]},proxy:true}],null,false,3769241458)},[_c('b-form-input',{staticStyle:{"'border-color":"rgba(255, 255, 255, 0.4)'"},style:(_vm.isDarkSkin
                  ? 'background: #17171A;opacity: 0.7;color:white'
                  : 'background: #efefef'),attrs:{"disabled":"disabled"},model:{value:(_vm.oldTimeFormat),callback:function ($$v) {_vm.oldTimeFormat=$$v},expression:"oldTimeFormat"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.isThereNewFormat)?_c('ValidationProvider',{attrs:{"name":"contactHour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"contactHour","label-class":"font-weight-bolder","label-for":"input-horizontal"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between kendo-width"},[_c('div',[_vm._v("Hour:")]),_c('div',{staticClass:"text-primary cursor-pointer",attrs:{"id":'hours-target'}},[_vm._v(" ? ")]),(_vm.hoursToSee)?_c('b-tooltip',{attrs:{"custom-class":"ga","target":'hours-target',"triggers":"hover","placement":"top"}},[_c('div',[_c('h5',{staticClass:"font-weight-bolder"},[_vm._v("Office Hours")]),_vm._l((_vm.hoursToSee),function(item,i){return _c('span',{key:i},[_c('span',{staticClass:"font-weight-bolder text-justify",staticStyle:{"max-width":"1px !important"}},[_vm._v(_vm._s(item.day)+" ")]),_c('span',{staticClass:"text-justify",staticStyle:{"max-width":"10px !important"}},[_vm._v(" "+_vm._s(": From: " + (item.from ? item.from : "00:00") + " To: " + (item.to ? item.to : "23:59")))]),_c('br')])})],2)]):_vm._e()],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"text-danger font-weight-light mb-0 mt-0"},[_vm._v(" Please set the time to call this client according to the information showed above: ")])]),_c('div',{staticClass:"w-25"},[_c('kendo-timepicker',{staticClass:"w-100 rounded bg-transparent k-picker-custom",class:{
                    'border-danger': errors[0] || !_vm.newHourToContactIsValid,
                  },attrs:{"id":"contactHour","format":'HH:mm',"minutes-step":"30","placeholder":"Select hour"},on:{"input":function($event){return _vm.showChangedValue()}},model:{value:(_vm.newHourToContact),callback:function ($$v) {_vm.newHourToContact=$$v},expression:"newHourToContact"}})],1)])]}}],null,false,2749420103)}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Observation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Observation","label-for":"Observation","label-cols-md":"2"}},[_c('b-form-textarea',{attrs:{"id":"Observation","state":errors[0] ? false : null},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }