<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="$refs.refClientsList.refresh()"
      @reset-all-filters="updateTable"
    >
      <template #buttons>
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
        <!-- Button Send To Connection -->
        <b-button
          variant="success"
          class="ml-1"
          @click="openModalSendToConnection"
          :disabled="clients_connection.length > 0 ? false : true"
          :title="messageNotFound ? messageNotFound : ''"
          :style="
            messageNotFound
              ? 'background-color: red !important; border-color: red !important;'
              : ''
          "
        >
          <feather-icon
            :icon="messageNotFound ? 'UserXIcon' : 'SendIcon'"
            class="mr-50"
          />Send To Connection
        </b-button>
        <!-- Button refresh -->
        <b-button
          v-if="isCeo || isSupervisor"
          variant="success"
          class="ml-1"
          :disabled="pnttime"
          @click="contador"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />{{
            pnttime == false
              ? "Refresh"
              : tiempominutos + ":" + tiemposegundos + " sec"
          }}
        </b-button>
      </template>
      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[6]" />
      </template>
      <template #table>
        <b-table
          slot="table"
          ref="refClientsList"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Selected Title -->
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>

          <!-- Selected -->
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSms"
                :value="data.item"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>

          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <router-link
              class
              :to="{
                name: 'credit-experts-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <span v-if="data.item.user_responsible" class="text-warning">
              <tabler-icon
                :icon="
                  data.item.user_responsible === currentUser.user_id
                    ? 'StarIcon'
                    : 'LockIcon'
                "
                size="15"
                style="margin-left: 5px"
              />
              <strong>CEO's client</strong>
            </span>
            <div>
              <div class="d-flex" style="gap: 5px">
                <span>{{ data.item.account }}</span>
                <b-badge v-if="data.item.claims != 0" variant="light-info">
                  IN CLAIM ({{ data.item.claims }})
                </b-badge>
              </div>
              <status-account :account="data.item" />
            </div>
            <div>
              <div class="text-primary">
                {{ data.item.eeuustate }}
              </div>
              <div
                v-if="
                  data.item.in_connection == 'ADMINISTRATION' &&
                  data.item.status_connection == 'TRANSITION'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-info"></div>
                </span>
                <span style="margin-left: 15px">Administration</span>
                <br />
              </div>
              <div
                style="position: relative"
                v-if="
                  data.item.in_connection == 'ORIGIN' &&
                  data.item.status_connection == 'REJECTED'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-danger"></div>
                </span>
                <span style="margin-left: 15px">Rejected</span>
                <br />
              </div>
              <span> {{ data.item.mobile }}</span>
            </div>
            <div>
              <span
                v-if="
                  data.item.date_income &&
                  (data.item.status == 5 || data.item.status == 2)
                "
              >
                <b-badge class="font10" variant="success"
                  >Date Income:
                  {{ data.item.date_income | myGlobalDay }}</b-badge
                >
              </span>
            </div>
            <div>
              <span
                v-if="
                  data.item.client_type_id == 1 &&
                  data.item.migration == 'Accepted'
                "
              >
                <b-badge class="font10" variant="success">Migration</b-badge>
              </span>
            </div>
          </template>

          <!-- Column App -->
          <template #cell(date_activation)="data">
            <div
              style="text-align: center"
              class="text-bold"
              :class="
                data.item.date_activation != null ? 'text-success' : 'text-info'
              "
            >
              {{ data.item.date_activation ? "Activated" : "No" }}
              <br />
              {{ data.item.date_activation | myGlobalDay }}
            </div>
          </template>
          <!-- Column Fee and Charges -->
          <template #cell(charges)="data">
            {{ data.item.charges | formatMoney }}
          </template>
          <!-- Column MP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(monthly_amount)="data"
          >
            {{ data.item.monthly_amount | formatMoney }}
          </template>
          <!-- Column LP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(last_payment)="data"
          >
            <div>
              <span style="margin-left: 15px">
                <b-img-lazy
                  v-if="data.item.state_lp == 1"
                  :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                />
                <b-img-lazy
                  v-else
                  :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                />
                {{
                  data.item.last_payment == "-"
                    ? ""
                    : data.item.last_payment | myGlobal
                }}
              </span>
            </div>
            <div
              class="mp-pending text-center"
              v-if="data.item.pending_payments == 1"
            >
              <feather-icon
                icon="CreditCardIcon"
                style="margin-left: 15px; margin-top: 5px"
                size="18"
                v-b-tooltip.hover
                title="1 Monthly Payment Pending"
              ></feather-icon>
            </div>
          </template>
          <!-- Column PT -->
          <template #cell(type_payment)="data">
            <status-payment :account="data.item" />
          </template>

          <!-- column SCORE -->
          <template #cell(score)="data">
            <div>
              <b-badge
                v-if="data.item.score"
                style="width: 50px"
                pill
                :variant="
                  data.item.score <= 659 && !data.item.score
                    ? 'danger'
                    : data.item.score >= 660 && data.item.score <= 699
                    ? 'warning'
                    : data.item.score >= 700 && data.item.score <= 759
                    ? 'primary'
                    : data.item.score >= 760 && data.item.score <= 850
                    ? 'success'
                    : 'danger'
                "
              >
                {{ data.item.score }}
              </b-badge>
            </div>
          </template>

          <!-- column GOALS -->
          <template #cell(goals)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <template v-if="Array.isArray(data.item.answer_goals)">
                  <li
                    v-for="(goals, index) in data.item.answer_goals"
                    :key="index"
                    style=""
                  >
                    <span>{{
                      goals.id == "goal-1"
                        ? "HOUSE"
                        : goals.id == "goal-2"
                        ? "CAR"
                        : goals.id == "goal-3"
                        ? "HOUSE REFINANCE"
                        : goals.id == "goal-4"
                        ? "CAR REFINANCE"
                        : goals.id == "goal-5"
                        ? "EXCELLENT CREDIT"
                        : ""
                    }}</span>
                  </li>
                </template>
                <template v-else>
                  <li style="width: 250px" v-if="data.item.answer_goals">
                    <span>{{ data.item.answer_goals }}</span>
                  </li>
                </template>
              </ul>
            </div>
          </template>

          <!-- Column ADVISOR -->
          <template #cell(advisor_name)="data">
            <feather-icon
              v-if="data.item.advisor_id"
              id="icon"
              :title="
                data.item.state_advisor == 1
                  ? 'Active'
                  : data.item.state_advisor == 2
                  ? 'Busy'
                  : data.item.state_advisor == 3
                  ? 'Away'
                  : 'Offline'
              "
              icon="CircleIcon"
              size="13"
              :style="
                data.item.state_advisor == 1
                  ? 'background:green'
                  : data.item.state_advisor == 2
                  ? 'background:orange'
                  : data.item.state_advisor == 3
                  ? 'background:red'
                  : 'background:#ccc'
              "
            />
            <span v-if="data.item.advisor_id">
              {{ data.item.advisor_name }}
            </span>
            <span v-else>
              <b-form-select
                v-if="isCeo || isSupervisor || isTeamLeader"
                v-model="data.item.advisor_id"
                :options="filters[5].options"
                value-field="id"
                text-field="user_name"
                @input="changeAdvisor(data.item)"
              >
              </b-form-select>
            </span>
          </template>

          <!-- Column Access Credential-->
          <template #cell(account_id)="data">
            <span
              class="cursor-pointer"
              @click="
                openModalListCredentials(
                  data.item.account_id,
                  data.item.account,
                  data.item.lead_name
                )
              "
              >AC</span
            >
          </template>

          <!-- CREATE AT -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobal }}
          </template>

          <!-- Column SERVICES -->
          <template
            v-if="
              isCeo || isSupervisor || isTeamLeader || currentUser.role_id == 3
            "
            #cell(services)="data"
          >
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Add Service -->
              <b-dropdown-item
                variant="warning"
                @click="modalAddService(data.item)"
              >
                <feather-icon icon="FilePlusIcon" />
                <span class="align-middle ml-50">Add service</span>
              </b-dropdown-item>

              <!-- Change Service -->
              <b-dropdown-item
                :variant="'success'"
                @click="modalChangeService(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Change service</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(typedoc)="data">
            <span>{{
              data.item.ssn
                ? "SSN"
                : data.item.itin
                ? "ITIN"
                : data.item.other
                ? "CPN"
                : ""
            }}</span>
          </template>
          <template #cell(migration)="data">
            <div
              :class="data.value == 'Pending' ? 'text-primary' : 'text-danger'"
            >
              {{ data.value }}
            </div>
          </template>
          <template #cell(tracking_migration)="data">
            <center>
              <feather-icon
                icon="ListIcon"
                size="20"
                class="text-primary cursor-pointer"
                @click="
                  openTrackingModal({
                    id: data.item.account_id,
                    client_name: data.item.lead_name,
                    account: data.item.account,
                  })
                "
              />
            </center>
          </template>

          <!-- Column ACTIONS -->
          <template
            v-if="
              isCeo ||
              isSupervisor ||
              isTeamLeader ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 4
            "
            #cell(actions)="data"
          >
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- SendToSales -->
              <!-- <b-dropdown-item
                v-if="
                  data.item.is_in_sale_process === 0 &&
                  $route.matched[0].meta.module === 6
                "
                v-b-tooltip.hover.left="'Send to Sales'"
                v-b-modal.modal-primary
                @click="openDerivationActionModal(data.item, data.index)"
              >
                <feather-icon icon="CornerUpLeftIcon" />
                <span class="align-middle ml-50">Send to Sales</span>
              </b-dropdown-item> -->
              <!-- SalesProcessTracking -->
              <b-dropdown-item
                v-if="
                  $route.matched[0].meta.module === 6 &&
                  data.item.is_derivated === 1
                "
                v-b-tooltip.hover.left="'Sales process tracking'"
                v-b-modal.modal-primary
                variant="success"
                @click="openDerivationTrackingModal(data.item)"
              >
                <feather-icon icon="DollarSignIcon" />
                <span class="align-middle ml-50">Sales process tracking</span>
              </b-dropdown-item>
              <!-- sendToCallReturns -->
              <b-dropdown-item
                v-if="
                  data.item.status_call_round == 1 && currentUser.role_id != 4
                "
                v-b-tooltip.hover.left="'Send to call rounds'"
                v-b-modal.modal-primary
                variant="primary"
                @click="
                  modalToCallOpen(
                    data.item.account_id,
                    data.item.lead_name,
                    data.item
                  )
                "
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Send to call rounds</span>
              </b-dropdown-item>
              <!-- sendSMS -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send SMS'"
                v-b-modal.modal-primary
                @click="modalSmsOpen(data.item.account_id, data.item.lead_name)"
              >
                <feather-icon icon="MessageSquareIcon" />
                <span class="align-middle ml-50">SMS</span>
              </b-dropdown-item>
              <!-- SendEmail -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send Email'"
                v-b-modal.modal-primary
                @click="
                  modalSendEmailOpen(data.item.account_id, data.item.lead_name)
                "
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Send Email</span>
              </b-dropdown-item>
              <!-- SendToConnection -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send To Connection'"
                v-b-modal.modal-primary
                v-if="
                  (isCeo || isSupervisor || isTeamLeader) &&
                  (data.item.status == 2 || data.item.status == 5) &&
                  (data.item.in_connection == 'ORIGIN' ||
                    (data.item.in_connection == 'ADMINISTRATION' &&
                      data.item.status_connection == 'REJECTED'))
                "
                @click="openModalSendClientToConnection(data.item)"
              >
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Connection</span>
              </b-dropdown-item>

              <!-- SendToConnection -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Migrate To Digital'"
                v-b-modal.modal-primary
                v-if="data.item.migration != 'Pending'"
                @click="
                  openMigrateToDigital({
                    id: data.item.account_id,
                    client_name: data.item.lead_name,
                    account: data.item.account,
                  })
                "
              >
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Migrate to Digital</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-tooltip.hover.left="'Migrate To Digital'"
                v-b-modal.modal-primary
                v-if="!data.item.advisor_id"
              >
                <feather-icon icon="UserIcon" />
                <span
                  class="align-middle ml-50"
                  @click="
                    openModalAssignAdvisor(
                      data.item.account,
                      data.item.lead_name,
                      data.item.account_id
                    )
                  "
                  >Assign Advisor</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td></b-td>
              <b-td></b-td>
              <b-td>
                <span style="font-size: 15px">Subtotal:</span>
              </b-td>
              <b-td>
                <b-badge style="font-size: 15px">
                  {{ subtotal | formatMoney }}</b-badge
                >
              </b-td>
            </b-tr>
            <b-tr>
              <b-td></b-td>
              <b-td></b-td>
              <b-td>
                <span style="font-size: 15px">Total:</span>
              </b-td>
              <b-td>
                <b-badge style="font-size: 15px">{{
                  totalSearch | formatMoney
                }}</b-badge>
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="name_clients_arr[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <!-- Modal call rounds -->
    <modal-call-rounds
      v-if="modalCall"
      :modal-call-rounds="modalCall"
      :name-leads="clientsSms"
      :type-call="0"
      @updateTable="$refs.refClientsList.refresh()"
      @closeModal="closeModalCallRounds"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="name_clients_arr"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <list-access-credentials-modal
      v-if="modalListCredentialsOn"
      :credential-id="credentialId"
      :account-number="accountNumber"
      :client-name="clientNameToPass"
      @close="closeModalListCredentials"
    />
    <modal-send-client-to-connection
      v-if="showModalSendToConnection"
      :modal-client-to-connection="showModalSendToConnection"
      :name-client="name_clients_arr"
      :destiny="'CONNECTION'"
      @onClose="closeModalSendToConnection"
      @onSubmit="sendToConnection($event)"
    ></modal-send-client-to-connection>
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />

    <request-modal
      v-if="isRequestModalOpened"
      :current-client="currentClient"
      :current_user="currentUser.user_id"
      @close="closeMigrateToDigital()"
      @update="$refs.refClientsList.refresh()"
    />
    <tracking-modal
      v-if="isTrackingModalOpened"
      :current-client="currentClient"
      @close="closeTrackingModal()"
    />
    <!--    derivation modal-->
    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @send="updateRow"
      @closeModal="closeDerivationActionModal"
    />
    <!--    derivation tracking modal-->
    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="closeDerivationTrackingModal"
    />
    <!--    assign advisor modal-->
    <assign-advisor-modal
      :account-client="currentClient.clientAccount"
      :name-client="currentClient.clientName"
      :client-account-id="currentClient.clientAccountId"
      v-if="modalAssignAdvisor"
      :active="modalAssignAdvisor"
      @close="closeModalAssignAdvisor($event)"
    />
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/credit-experts/views/clients/components/clients/FilterSlot.vue";
import fields from "@/views/credit-experts/views/clients/data/fields.clients.data";

// service
import ClientServiceRegular from "@/views/credit-experts/views/clients/services/ce-clients.services";

import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalCallRounds from "@/views/credit-experts/views/clients/components/clients/modals/SendToCallRounds.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import GlobalService from "@/service/global/index";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FiltersClientsData from "@/views/credit-experts/views/clients/data/filters.clients.data";
import ListAccessCredentialsModal from "@/views/credit-experts/views/clients/components/clients/modals/ListAccessCredentialsModal.vue";
import ModalSendClientToConnection from "@/views/credit-experts/views/clients/components/clients/modals/ModalSendClientToConnection.vue";
import { mapGetters, mapActions } from "vuex";

import RequestModal from "./RequestModal.vue";
import TrackingModal from "./TrackingModal.vue";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import AssignAdvisorModal from "@/views/credit-experts/views/clients/components/clients/modals/AssignAdvisorModal.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { amgApi } from "@/service/axios";
import Vue from "vue";

export default {
  components: {
    DerivationTrackingModal,
    DerivationActionModal,
    FilterSlot,
    // modals
    BusinessModal,
    CreditExpertsModal,
    ListAccessCredentialsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalCallRounds,
    ModalAddService,
    ModalSendEmail,
    ModalSendSms,
    StatusPayment,
    StatusAccount,
    ActionsTable,
    ModalSendClientToConnection,

    RequestModal,
    TrackingModal,
    AssignAdvisorModal,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      subtotal: 0,
      totalSearch: 0,
      messageNotFound: "",
      showModalSendToConnection: false,
      headerS: {},
      goals: [],
      clientNameToPass: "",
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      typeModal: null,
      isIncomeLoyalOrHold: 0,
      clients: null,
      clientsSms: [],
      selectAll: false, // total
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      filters: FiltersClientsData,
      fields,
      isRequestModalOpened: false,
      isTrackingModalOpened: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      modalAssignAdvisor: false,
      modalCall: false,
      serviceOptions: [],
      actionOptions: [],
      detailsClient: {},
      currentClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      items: [],
      event: null,
      modalListCredentialsOn: false,
      credentialId: "",
      accountNumber: "",
      derivationActionModal: false,
      derivationData: null,
      derivationIndex: null,
      derivationTrackingModal: false,
      typeAddChange: null,
    };
  },
  async created() {
    await this.usersPrograms();
    await this.usersAllPrograms();
    await this.getStatesEeuu();
    this.filters[0].model = true;
    this.filters[1].model = false;
    this.filters[2].model = false;
    this.getAllStatusClientAccount();
  },
  async mounted() {
    if (this.currentUser.role_id == 3) {
      this.fields[8].visible = false;
    }
    this.serviceOptions.push("addService");
    this.serviceOptions.push("changeService");
    this.actionOptions.push("sendToCallReturns");
    this.actionOptions.push("sendSMS");
    this.actionOptions.push("sendEmail");
  },
  methods: {
    async getStatesEeuu() {
      try {
        let dataState = await GlobalService.getStatesEEuu();
        this.filters[12].options = dataState.data;
        this.filter[12].options.unshift({
          state: "All",
          slug: 0,
        });
      } catch (e) {}
    },
    updateTable() {
      this.filters[0].model = true;
      this.filters[1].model = false;
      this.filters[2].model = false;
      this.filters[3].model = null;
      this.filters[4].model = null;
      this.filters[5].model = null;
      this.filters[6].model = null;
      this.filters[7].model = null;
      this.filters[8].model = null;
      this.filters[9].model = null;
      this.filters[10].model = null;
      this.filters[11].model = null;
      this.filters[12].model = null;
      this.filters[13].model = null;
      this.filterPrincipal.model = "";
      this.$refs.refClientsList.refresh();
    },
    // actions
    async modalToCallOpen(id, name, account, nameAccount, nameProgram) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id,
            name,
            account,
            nameAccount,
            nameProgram,
          },
        ];
        this.modalCall = true;
      }
    },
    numberFormatted(n) {
      // This function adds comma thousand separator to a number
      let [numberPart, decimalPart] = n.toString().split(".");
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return (
        numberPart.replace(thousands, ",") +
        (decimalPart ? "." + decimalPart : "")
      );
    },
    openModalSendToConnection() {
      var namecl = [];
      this.clients_connection.map((dato) => {
        namecl.push({
          name: dato.lead_name,
          id: dato.account_id,
          account: dato.account,
          status: dato.status,
          client_id: dato.client_id,
        });
      });
      this.name_clients_arr = namecl;
      this.showModalSendToConnection = true;
    },
    openModalSendClientToConnection(client) {
      let namecl = [];
      namecl.push({
        name: client.lead_name,
        id: client.account_id,
        account: client.account,
        status: client.status,
        client_id: client.client_id,
      });
      this.name_clients_arr = namecl;
      this.showModalSendToConnection = true;
    },
    async sendToConnection(clients, comment) {
      this.addPreloader();
      clients.forEach(async (item) => {
        let in_connection = item.status == 2 ? "ADMINISTRATION" : "CONNECTION";
        let status_connection = item.status == 2 ? "HOLD" : "LOYAL";
        try {
          let params = {
            client_id: item.client_id,
            client_account_id: item.id,
            in_connection: in_connection,
            status_connection: status_connection,
            reason_connection: "",
            advisor_id: "",
            comment: comment,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientService.passClientToConnection(params);
          if (response.status == 200) {
            this.$swal({
              title: "Save Succesfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((res) => {
              if (res) {
                this.$refs.refClientsList.refresh();
                this.removePreloader();
                this.showModalSendToConnection = false;
              }
            });
          }
        } catch (e) {
          throw e;
        }
      });
      this.removePreloader();
    },
    closeModalSendToConnection() {
      this.showModalSendToConnection = false;
    },
    openModalListCredentials(id, accNumber, clientName) {
      this.modalListCredentialsOn = true;
      this.credentialId = id;
      this.accountNumber = accNumber;
      this.clientNameToPass = clientName;
    },
    closeModalListCredentials() {
      this.modalListCredentialsOn = false;
    },
    async modalSendEmailOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.name_clients_arr = [{ id, name }];
        this.modalSendEmail = true;
      }
    },
    async modalSmsOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.name_clients_arr = [{ id, name }];
        this.modalSendSms = true;
      }
    },
    // buttons
    openModalCallRounds() {
      this.modalCall = true;
    },
    // openModalSendSms() {
    //   var namecl = []
    //   this.clientsSms.map((dato) => {
    //     namecl.push({ name: dato.lead_name, id: dato.account_id })
    //   })
    //   this.name_clients_arr = namecl
    //   this.modalSendSms = true
    // },
    openModalSendEmail() {
      var namecl = [];
      this.clientsSms.map((dato) => {
        namecl.push({ name: dato.lead_name, id: dato.account_id });
      });
      this.name_clients_arr = namecl;
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    closeModalCallRounds() {
      this.modalCall = false;
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        if (this.filters[7].model == 1) {
          this.filters[8].visible = true;
        } else {
          this.filters[8].model = null;
          this.filters[8].visible = false;
        }
      }
    },
    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      this.tiemposegundos = this.tiemposegundos;
      this.tiempominutos = this.tiempominutos;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },
    selectedAll() {
      if (this.selectAll) {
        var namecl = [];
        this.items.map((dato) => {
          namecl.push(dato);
        });
        this.clientsSms = namecl;
      } else {
        this.clientsSms = [];
      }
    },
    openTrackingModal(id) {
      this.isTrackingModalOpened = true;
      this.currentClient = Object.assign({}, id);
    },
    openMigrateToDigital(accountId) {
      this.isRequestModalOpened = true;
      this.currentClient = Object.assign({}, accountId);
    },
    closeMigrateToDigital() {
      this.isRequestModalOpened = false;
      this.currentClient = {};
    },
    closeTrackingModal() {
      this.isTrackingModalOpened = false;
      this.currentClient = {};
    },
    async myProvider(ctx) {
      let sortBy = 17;
      let sortDirection = "desc";
      this.selectAll = false;
      if (ctx.sortBy.length > 0) {
        const columnTable = this.fields.find((x) => x.key === ctx.sortBy);
        sortBy = columnTable.orderBy;
        sortDirection = ctx.sortDesc ? "desc" : "asc";
      }
      try {
        let program = 3;
        if (this.isCeo || this.isSupervisor || this.isTeamLeader) {
          this.advisor = this.filters[5].model;
        } else if (this.currentUser.role_id == 3) {
          this.advisor = this.currentUser.user_id;
        } else {
          this.advisor = null;
        }
        const params = {
          state_eeuu: this.filters[12].model,
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[3].model,
          to: this.filters[4].model,
          program: program,
          orderby: sortBy,
          order: sortDirection,
          status: this.filters[6].model,
          advisor: this.advisor,
          type: this.filters[7].model,
          day: this.paymentDay,
          score: this.filters[9].model,
          pGoal: this.filters[11].model,
          rol_id: this.currentUser.role_id,
          typescredential: this.filters[10].model,
          hasApp: this.filters[13].model,
          status_search: this.getStatusSearch(),
          is_digital: 1,
          recovered: this.filters[14].model,
          waitings: this.$route.meta.isWaiting,
          page: ctx.currentPage,
        };

        const response = await ClientServiceRegular.ceRegularSearchClients(
          params
        );
        response.data.map((datos) => {
          if (datos.answer_goals) {
            try {
              datos.answer_goals = JSON.parse(
                datos.answer_goals.replace(/\\/g, '"')
              );
            } catch (e) {
              if (!(e instanceof SyntaxError)) {
                throw e;
              }
            }
          }
        });
        this.items = response.data;
        this.clients = this.items;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.last_page;
        this.totalRows = response.total;
        this.toPage = response.to;
        return this.items || [];
      } catch (error) {
        return [];
      }
    },
    getStatusSearch() {
      let result = null;

      if (this.filters[0].model) {
        result = this.filters[0].value;
      } else if (this.filters[1].model) {
        result = this.filters[1].value;
      } else if (this.filters[2].model) {
        result = this.filters[2].value;
      }
      return result;
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params);
          this.filters[5].label = "Advisor";
          this.filters[5].selectText = "user_name";
          this.filters[5].options = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    onRowDelete(id) {},
    async onRowEdit(data) {},
    async modalChangeService(data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowProcess(id) {},
    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    async modalSendSMS(data) {
      const result = await this.showConfirmSwal("Are sure want to send SMS?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    async modalSendEmailMenu(data) {
      const result = await this.showConfirmSwal("Are sure want to send Email?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = JSON.stringify([
        { program: "Credit Experts" },
      ]);
      this.typeModal = type;
      Vue.set(this.detailsClient, "event_id", this.event)
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.detailsClient.account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeAddChange,
        user_id: this.currentUser.user_id,
        module: this.moduleId,
        id_score: null,
        json_ce: null,
        type_client: "CLIENT",
      };
      try {
        const result = await LeadService.insertSaleMade(param);
        if (result.status == 200) {
          if (data.program.id == 4) {
            // copy settlements result (ncr) to sales made modal debt solution
            const params = {
              ca_id_first_program: this.detailsClient.account_id,
              user_id: this.currentUser.user_id,
              event_id: this.event,
              lead_id: this.detailsClient.lead_id,
            };
            const { data } = await amgApi.post(
              "sales-made/copy-settlements-first-program",
              params
            );
          }
          this.modalOpenAddService = false;
          this.$router
            .push({ name: "sales-made-creditexperts-client-pending" })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    updateRow() {
      this.$set(this.items[this.derivationIndex], "is_derivated", 1);
      this.$set(this.items[this.derivationIndex], "is_in_sale_process", 1);
    },
    openDerivationActionModal(item, index) {
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    openModalAssignAdvisor(clientAccount, clientName, clientAccountId) {
      const clientData = { clientAccount, clientName, clientAccountId };
      this.currentClient = Object.assign({}, clientData);
      this.modalAssignAdvisor = true;
    },
    closeModalAssignAdvisor(event) {
      if (event) this.$refs.refClientsList.refresh();
      this.currentClient = {};
      this.modalAssignAdvisor = false;
    },
    async changeAdvisor(item) {
      item.advisor_name = this.filters[5].options.find(
        (option) => option.id == item.advisor_id
      ).user_name;
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            advisor_id: item.advisor_id,
            id: item.account_id,
            session_id: this.currentUser.user_id,
            subject: "Advisor Change for " + item.lead_name,
            content: "",
            modul: this.$route.matched[0].meta.module,
          };
          this.addPreloader();
          const response = await ClientDashboardService.advisorChangeWoContent(
            params
          );
          this.removePreloader();
          if (response.status == 200) {
            this.$refs.refClientsList.refresh();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          }
        } catch (error) {
          this.removePreloader();
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
          console.log(error);
        }
      } else {
        item.advisor_id = null;
        item.advisor_name = null;
      }
    },
    async getAllStatusClientAccount() {
      this.filters[6].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[6].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[6].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      this.fields[0].visible = this.currentUser.role_id != 4;
      this.fields[2].visible = this.moduleId == 2 || this.moduleId == 4;
      this.fields[3].visible = this.moduleId == 6;
      this.fields[4].visible =
        (this.moduleId != 2 && this.ç) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4);
      this.fields[5].visible =
        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4);
      this.fields[6].visible =
        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4);
      this.fields[7].visible =
        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4);
      this.fields[10].visible =
        this.currentUser.role_id != 3 || this.moduleId == 2;
      this.fields[11].visible =
        (this.moduleId == 6 || this.moduleId == 7) &&
        this.currentUser.role_id == 4;
      this.fields[13].visible =
        this.isCeo ||
        this.isSupervisor ||
        this.isTeamLeader ||
        this.currentUser.role_id == 3;
      this.fields[15].visible = this.currentUser.role_id != 4;
      return this.fields.filter((item) => item.visible === true);
    },
    clients_connection() {
      let array_to_connection = this.clientsSms.filter((item) => {
        return (
          (this.isCeo || this.isSupervisor || this.isTeamLeader) &&
          (item.status == 2 || item.status == 5) &&
          (item.in_connection == "ORIGIN" ||
            (item.in_connection == "ADMINISTRATION" &&
              item.status_connection == "REJECTED"))
        );
      });
      if (array_to_connection.length == 0 && this.selectAll) {
        this.messageNotFound = "There are no clients to send";
      } else {
        this.messageNotFound = "";
      }
      return array_to_connection;
    },
    paymentType: {
      get() {
        return this.filters[7].model;
      },
      set(value) {
        this.filters[7].model = value;
      },
    },
    filterGeneral: {
      get() {
        return this.filters[0].model;
      },
      set(value) {
        this.filters[0].model = value;
      },
    },
    filterLoyal: {
      get() {
        return this.filters[1].model;
      },
      set(value) {
        this.filters[1].model = value;
      },
    },
    filterHold: {
      get() {
        return this.filters[2].model;
      },
      set(value) {
        this.filters[2].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filters[8].model;
      },
      set(value) {
        this.filters[8].visible = value;
      },
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal == 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
    filterGeneral(newVal) {
      if (newVal) {
        this.filterLoyal = false;
        this.filterHold = false;
        this.filters[3].placeholder = "Date";
        this.filters[4].placeholder = "Date";
      }
    },
    filterLoyal(newVal) {
      if (newVal) {
        this.filterGeneral = false;
        this.filterHold = false;
        this.filters[3].placeholder = "Date (Loyal Income)";
        this.filters[4].placeholder = "Date (Loyal Income)";
      }
    },
    filterHold(newVal) {
      if (newVal) {
        this.filterGeneral = false;
        this.filterLoyal = false;
        this.filters[3].placeholder = "Date (Hold Income)";
        this.filters[4].placeholder = "Date (Hold Income)";
      }
    },
    items(newVal) {
      this.subtotal = newVal.reduce((acc, item) => {
        return Number(acc) + Number(item.charges);
      }, 0);

      this.totalSearch = newVal.length > 0 ? Number(newVal[0].sum_total) : 0;
    },
  },
};
</script>

<style scoped lang="scss">
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.status_circle {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}

.mp-pending {
  animation: growup 1.5s ease-in-out infinite;
  top: calc(50% - 10px);
}
@keyframes growup {
  0% {
    transform: scale(1);
    color: #ff9f43;
  }
  50% {
    transform: scale(0.99);
    color: #fc3a31;
  }
  100% {
    transform: scale(1);
    color: #ff9f43;
  }
}
</style>
