import { amgApi } from "@/service/axios";

class ClientsGridServive {
	async getTrackingMigrationRequest(data) {
        try {
            let response = await amgApi.post("/clients/tracking-clients-migration", data)
		    return response
        } catch (e) {
            throw e
        }
		
	}
    async getOfficeHoursPerDay() {
        try {
            const data = await amgApi.post("credit-experts-digital/get-office-hours-per-day");
            return data;
        } catch (error) {
            console.log("Error getOfficeHoursPerDay");
            throw error
        }
    }
    async getOldTimeFormatClients(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/clients-get-old-time-format",params);
            return data;
        } catch (error) {
            console.log("Error getOldTimeFormatClients");
            throw error
        }
    }
    async setNewTimeFormatClients(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/clients-set-new-time-format",params);
            return data;
        } catch (error) {
            console.log("Error setNewTimeFormatClients");
            throw error
        }
    }
    async DigitalMigrationRequest(data) {
        try {
            let res = await amgApi.post("/clients/clients-migration-request", data)
            return res.data
        } catch (e) {
            throw e
        }
    }
    
}

export default new ClientsGridServive();